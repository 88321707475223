import { FormTextField, INoteInputs } from 'components';
import { RelationshipNotesModalContext } from 'modals';
import { useContext, useEffect } from 'react';
import { Control, FieldErrors, useController } from 'react-hook-form';
import { NoteFormField } from '../..';

interface NoteTitleProps {
  control: Control;
  initialValue: string | undefined;
  errors: FieldErrors<INoteInputs>;
  label: string;
}

export function NoteTitle({
  control,
  initialValue,
  errors,
  label,
}: NoteTitleProps) {
  const { setIsDirtyNote } = useContext(RelationshipNotesModalContext);

  const {
    field: { value },
    formState,
  } = useController({ name: NoteFormField.Title, control });

  useEffect(() => {
    setIsDirtyNote(!!Object.keys(formState.dirtyFields).length);
  }, [formState.dirtyFields, setIsDirtyNote, value]);

  return (
    <FormTextField
      name={NoteFormField.Title}
      control={control}
      label={label}
      errors={errors}
      required
      defaultValue={initialValue}
    />
  );
}
