import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage, useSettings } from '@guider-global/sanity-hooks';
import { TOTPSurvey } from '@guider-global/shared-types';
import {
  Button,
  Divider,
  FormSelectArea,
  FormTextArea,
  InfoIcon,
  Stack,
  StarRating,
  StarRatingInputValue,
  Text,
} from '@guider-global/ui';
import { useMediaQuery } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import {
  SelectInputValue,
  TextareaInputValue,
} from 'forms/SurveyForm/components';
import { interpolate } from 'functions';
import { useLocalization } from 'hooks';

import { useOTPSurveys } from 'hooks/useOTPSurveys';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';

type OTPSurveyFormProps = {
  survey: Partial<TOTPSurvey>;
  onSuccess: VoidFunction;
  lastName?: string;
  firstName?: string;
  programTypeVerb?: string;
  roleSingular?: string;
};

type OTPSurveyFormFields = {
  [key: string]: TextareaInputValue | SelectInputValue | StarRatingInputValue;
};

export function OTPSurveyForm({
  survey,
  onSuccess,
  lastName,
  firstName,
  programTypeVerb,
  roleSingular,
}: OTPSurveyFormProps) {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const [searchParams] = useSearchParams();
  const rating = searchParams.get('rating');

  const dispatch = useAppDispatch();

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { reqOTPSurveys } = useOTPSurveys({});

  const { settings } = useSettings({
    localeCode,
  });

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<OTPSurveyFormFields>({
    mode: 'onChange',
    defaultValues:
      survey.type === 'guide-feedback'
        ? {
            'guide-rating': {
              fieldType: 'scale',
              value: Number(rating) ?? 0,
            },
          }
        : {},
  });

  const onSubmit: SubmitHandler<OTPSurveyFormFields> = async (data) => {
    const result = await reqOTPSurveys({
      method: 'PATCH',
      url: `/otp/surveys/${survey.id}?userId=${survey.user}`,
      data: {
        // @ts-ignore
        surveyFields: Object.entries(data).map(([key, value]) => ({
          fieldSlug: key,
          fieldType: value?.fieldType,
          value: value?.value,
        })),
      },
    });

    if (result.status === 'success') {
      onSuccess();
      window.scrollTo(0, 0);
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: result.errors?.at(0)?.message,
          timeout: 5000,
        }),
      );
    }
  };

  const goalSortOrder = {
    fully: 0,
    partially: 1,
    not_at_all: 2,
    goals_have_changed: 3,
  };

  const goalOptions = Object.assign(
    goalSortOrder,
    settings?.guider_surveys.goals_survey.responses,
  );

  const skillsSortOrder = {
    vastly_improved: 0,
    somewhat_improved: 1,
    not_improved: 2,
    not_working_on_this_skill_at_the_moment: 3,
  };

  const skillOptions = Object.assign(
    skillsSortOrder,
    settings?.guider_surveys.skills_survey.responses,
  );

  const formData = useMemo(() => {
    let view;
    let disclaimer;

    switch (survey.type) {
      case 'guide-feedback':
        view = (
          <Stack direction={'column'} gap={3} py={3}>
            <StarRating
              control={control}
              name="guide-rating"
              heading={interpolate(
                settings?.guider_surveys?.guide_feedback_survey
                  ?.trainee_rating_question_title ?? '',
                {
                  guideSingular: roleSingular ?? '',
                },
              )}
              errorMessage={baseLanguage?.globals?.errors?.required_field}
            />
            <FormTextArea
              fieldType="text"
              label={interpolate(
                settings?.guider_surveys?.guide_feedback_survey
                  ?.trainee_feedback_question_title ?? '',
                {
                  guideFirstName: firstName ?? '',
                  programTypeVerb: programTypeVerb ?? '',
                  guideSingular: roleSingular ?? '',
                },
              )}
              sx={{ py: 2 }}
              name={survey.type}
              placeholder={interpolate(
                settings?.guider_surveys.guide_feedback_survey
                  .response_placeholder ?? '',
                { guideSingular: roleSingular ?? '' },
              )}
              control={control}
              errorMessage={baseLanguage?.globals?.errors?.required_field}
            />
          </Stack>
        );
        disclaimer = settings?.guider_surveys.guide_feedback_survey.disclaimer;

        break;
      case 'goals-feedback':
        view = (
          <Stack direction={'column'} gap={3} py={3}>
            {// @ts-ignore
            survey?.metaData?.goals?.map((goal, index) => {
              return (
                <Stack direction={'column'} gap={4} key={goal.goalId}>
                  <FormSelectArea
                    key={goal.goalId}
                    control={control}
                    heading={interpolate(
                      survey.metaData?.role === 'guide'
                        ? settings?.guider_surveys?.goals_survey
                            ?.guide_question_title ?? ''
                        : settings?.guider_surveys?.goals_survey
                            ?.trainee_question_title ?? '',
                      {
                        goal: goal.goalName,
                        traineeFirstName: firstName ?? '',
                        traineeLastName: lastName ?? '',
                        guideFirstName: firstName ?? '',
                        guideLastName: lastName ?? '',
                        programTypeVerb: programTypeVerb ?? '',
                      },
                    )}
                    name={goal.goalId}
                    options={goalOptions}
                    errorMessage={baseLanguage?.globals?.errors?.required_field}
                  />
                  {survey?.metaData?.goals &&
                  survey.metaData.goals.length - 1 !== index ? (
                    <Divider />
                  ) : undefined}
                </Stack>
              );
            })}
          </Stack>
        );
        disclaimer = settings?.guider_surveys.goals_survey.disclaimer;

        break;
      case 'skills-feedback':
        view = (
          <Stack direction={'column'} gap={3} py={3}>
            {// @ts-ignore
            survey?.metaData?.skills?.map((skill, index) => {
              return (
                <Stack direction={'column'} gap={4} key={skill.skillId}>
                  <FormSelectArea
                    key={skill.skillId}
                    control={control}
                    heading={interpolate(
                      survey.metaData?.role === 'guide'
                        ? settings?.guider_surveys?.skills_survey
                            ?.guide_question_title ?? ''
                        : settings?.guider_surveys?.skills_survey
                            ?.trainee_question_title ?? '',
                      {
                        skill: skill.skillName,
                        traineeFirstName: firstName ?? '',
                        traineeLastName: lastName ?? '',
                        guideFirstName: firstName ?? '',
                        guideLastName: lastName ?? '',
                        programTypeVerb: programTypeVerb ?? '',
                      },
                    )}
                    name={skill.skillId}
                    options={skillOptions}
                    errorMessage={baseLanguage?.globals?.errors?.required_field}
                  />
                  {survey?.metaData?.skills &&
                  survey.metaData.skills.length - 1 !== index ? (
                    <Divider />
                  ) : undefined}
                </Stack>
              );
            })}
          </Stack>
        );
        disclaimer = settings?.guider_surveys.skills_survey.disclaimer;

        break;
      default:
        break;
    }

    return { view, disclaimer };
  }, [
    control,
    settings,
    survey,
    baseLanguage,
    goalOptions,
    skillOptions,
    programTypeVerb,
    firstName,
    lastName,
    roleSingular,
  ]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > *:not(:last-of-type)': { marginBottom: 3 },
        '& > *:nth-last-of-type(2)': { marginBottom: 0 },
      }}
    >
      {formData.view}
      <Stack direction={'row'} spacing={1}>
        <InfoIcon size="small" />
        <Text
          color={palette.text.secondary}
          variant="body1"
          text={formData?.disclaimer}
        />
      </Stack>
      <Box
        sx={{
          paddingY: 4,
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'sticky',
          left: 0,
          bottom: 0,
          backgroundColor: 'paper.background.primary',
        }}
      >
        <Button
          variant={'contained'}
          label={
            baseLanguage?.surveys?.feedback_requested_modal?.submit_button_label
          }
          type="submit"
          color="info"
          disabled={!isValid}
          sx={{ px: 3 }}
          fullWidth={isMobile}
        />
      </Box>
    </Box>
  );
}
