import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { Box } from '@mui/material';
import { PageCheckbox } from 'components';
import { useLocalization } from 'hooks';
import { RelationshipNotesModalContext } from 'modals';
import { useContext, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { INotesCreateFormProps, NoteFormField } from '../..';

type NoteCollaborateProps = Pick<
  INotesCreateFormProps,
  'control' | 'errors' | 'note'
> & {
  defaultValue: boolean;
  disabled: boolean;
};

export function NoteCollaborate({
  control,
  errors,
  defaultValue,
  disabled,
}: NoteCollaborateProps) {
  const { setIsDirtyNote } = useContext(RelationshipNotesModalContext);
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const collaborate_boolean_description =
    baseLanguage?.relationships?.notes?.notes_modal
      ?.notes_modal_create_edit_note?.collaborate_boolean_description;

  const collaborate_boolean_label =
    baseLanguage?.relationships?.notes?.notes_modal
      ?.notes_modal_create_edit_note?.collaborate_boolean_label;

  const {
    field: { value, onChange },
    formState,
  } = useController({ name: NoteFormField.IsPublic, control });

  const handleClick = () => {
    onChange(!value);
  };

  useEffect(() => {
    setIsDirtyNote(!!Object.keys(formState.dirtyFields).length);
  }, [formState.dirtyFields, setIsDirtyNote, value]);

  return (
    <Box>
      <PageCheckbox
        control={control}
        name={NoteFormField.IsPublic}
        errors={errors}
        defaultValue={defaultValue}
        heading={collaborate_boolean_label}
        description={collaborate_boolean_description}
        dataCy="relationship-notes-modal-note-form-collaborate-checkbox"
        sx={{ mb: 0, mt: 2.5, userSelect: 'none', cursor: 'pointer' }}
        disabled={disabled}
        onClick={!disabled ? handleClick : undefined}
      />
    </Box>
  );
}
