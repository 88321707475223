import { useMemo } from 'react';
import { Box } from '@mui/material';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { BaseLoadingButton } from '@guider-global/ui';
import { OTimeZones } from '@guider-global/shared-types';
import { FormAutocomplete } from 'components';
import { useAccountPreferencesForm } from './hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization } from 'hooks';

export * from './types';

export function AccountPreferencesForm() {
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const preferences = baseLanguage?.settings?.account?.preferences;
  const timezoneSelectLabel = preferences?.timezone_select_label;

  const {
    control,
    errors,
    handleSubmit,
    onSubmit,
    onError,
    isSubmitting,
    isDirty,
    isValid,
  } = useAccountPreferencesForm();
  const isNotDirty = !isDirty;
  const isInvalid = !isValid;

  const timezones = useMemo(
    () =>
      OTimeZones.map((timezone) => {
        const timezoneLabel = timezone.split('_').join(' ');
        return { label: timezoneLabel, value: timezone };
      }),
    [],
  );

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit, onError)}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        '& .MuiFormControl-root': {
          mt: 0,
        },
      }}
    >
      <FormAutocomplete
        defaultValue={undefined}
        name="timezone"
        control={control}
        label={timezoneSelectLabel}
        errors={errors}
        options={timezones}
        rules={{ required: true }}
      />
      <BaseLoadingButton
        data-cy="settings_account_preferences-button"
        type="submit"
        disabled={isNotDirty || isInvalid}
        loading={isSubmitting}
        sx={{
          width: { xs: '100%', md: 'fit-content' },
        }}
      >
        {baseLanguage?.globals?.common?.save_changes_button_label}
      </BaseLoadingButton>
    </Box>
  );
}
